@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Arabic:wght@100;200;300;400;500;600;700&family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");

html,
body:not([cds-text]) {
  font-size: var(--font-size-sm);
  font-family: "IBM Plex Sans", "IBM Plex Sans Arabic", Inter, sans-serif !important;
  line-height: var(--cds-global-typography-body-line-height);
  color: var(--color-text-100);
}
h1:not([cds-text]),
h2:not([cds-text]),
h3:not([cds-text]),
h4:not([cds-text]),
h5:not([cds-text]),
h6:not([cds-text]) {
  font-family: "IBM Plex Sans", "IBM Plex Sans Arabic", Inter, sans-serif !important;
}
body p:not([cds-text]) {
  font-family: "IBM Plex Sans", "IBM Plex Sans Arabic", Inter, sans-serif !important;
}

.app-container[dir="rtl"] {
  [shape="caret left"],
  [shape="caret right"],
  [shape="arrow left"],
  [shape="arrow right"] {
    transform: scaleX(-1);
  }
}

// Default Vendure light theme. The Clarity component custom properties
// are left as their defaults.
:root [cds-theme] {
  // Colors
  --color-grey-100: #fafafa;
  --color-grey-200: #f2f3f5;
  --color-grey-300: #bfc3cc;
  --color-grey-400: #97979a;
  --color-grey-500: #525456;
  --color-grey-600: #3f4445;
  --color-grey-700: #303233;
  --color-grey-800: #202223;
  --color-grey-900: #0f1011;

  --color-white: #fff;
  --color-black: #000;
  --color-weight-100: hsl(0 0% 95%);
  --color-weight-125: hsl(0 0% 93%);
  --color-weight-150: hsl(0 0% 90%);
  --color-weight-200: hsl(0 0% 85%);
  --color-weight-300: hsl(0 0% 75%);
  --color-weight-400: hsl(0 0% 65%);
  --color-weight-500: hsl(0 0% 55%);
  --color-weight-600: hsl(0 0% 45%);
  --color-weight-700: hsl(0 0% 35%);
  --color-weight-800: hsl(0 0% 25%);
  --color-weight-900: hsl(0 0% 15%);
  --color-weight-950: hsl(0 0% 10%);
  --color-weight-975: hsl(0 0% 7%);
  --color-weight-1000: hsl(0 0% 5%);

  --color-primary-100: hsl(0 0% 95%);
  --color-primary-125: hsl(0 0% 93%);
  --color-primary-150: hsl(0 0% 90%);
  --color-primary-200: hsl(0 0% 85%);
  --color-primary-300: hsl(0 0% 75%);
  --color-primary-400: hsl(0 0% 65%);
  --color-primary-500: hsl(0 0% 55%);
  --color-primary-600: hsl(0 0% 45%);
  --color-primary-700: hsl(0 0% 35%);
  --color-primary-800: hsl(0 0% 25%);
  --color-primary-900: hsl(0 0% 15%);
  --color-primary-950: hsl(0 0% 10%);
  --color-primary-975: hsl(0 0% 7%);
  --color-primary-1000: hsl(0 0% 5%);

  // --color-primary-100: hsl(0, 0%, 4%);
  // --color-primary-125: hsl(0, 0%, 4%);
  // --color-primary-150: hsl(0, 0%, 4%);
  // --color-primary-200: hsl(0, 0%, 4%);
  // --color-primary-300: hsl(0, 0%, 4%);
  // --color-primary-400: hsl(0, 0%, 4%);
  // --color-primary-500: hsl(0, 0%, 4%);
  // --color-primary-600: hsl(0, 0%, 4%);
  // --color-primary-700: hsl(0, 0%, 4%);
  // --color-primary-800: hsl(0, 0%, 4%);
  // --color-primary-900: hsl(0, 0%, 4%);
  // --color-primary-950: hsl(0, 0%, 4%);
  // --color-primary-975: hsl(0, 0%, 4%);
  // --color-primary-1000: hsl(0, 0%, 4%);

  // --color-primary-100: hsl(196 100% 95%);
  // --color-primary-125: hsl(196 100% 93%);
  // --color-primary-150: hsl(196 100% 90%);
  // --color-primary-200: hsl(196 100% 85%);
  // --color-primary-300: hsl(196 100% 75%);
  // --color-primary-400: hsl(196 100% 65%);
  // --color-primary-500: hsl(196 100% 55%);
  // --color-primary-600: hsl(196 100% 45%);
  // --color-primary-700: hsl(196 100% 35%);
  // --color-primary-800: hsl(196 100% 25%);
  // --color-primary-900: hsl(196 100% 15%);
  // --color-primary-950: hsl(196 100% 10%);
  // --color-primary-975: hsl(196 100% 7%);
  // --color-primary-1000: hsl(196 100 5%);

  --color-accent-100: hsl(35 100% 95%);
  --color-accent-125: hsl(35 100% 93%);
  --color-accent-150: hsl(35 100% 90%);
  --color-accent-200: hsl(35 100% 85%);
  --color-accent-300: hsl(35 100% 75%);
  --color-accent-400: hsl(35 100% 65%);
  --color-accent-500: hsl(35 100% 55%);
  --color-accent-600: hsl(35 100% 45%);
  --color-accent-700: hsl(35 100% 35%);
  --color-accent-800: hsl(35 100% 25%);
  --color-accent-900: hsl(35 100% 15%);
  --color-accent-950: hsl(35 100% 10%);
  --color-accent-975: hsl(35 100% 7%);
  --color-accent-1000: hsl(35 100% 5%);

  --color-success-100: hsl(158 76% 95%);
  --color-success-125: hsl(158 76% 93%);
  --color-success-150: hsl(158 76% 90%);
  --color-success-200: hsl(158 76% 85%);
  --color-success-300: hsl(158 76% 75%);
  --color-success-400: hsl(158 76% 65%);
  --color-success-500: hsl(158 76% 55%);
  --color-success-600: hsl(158 76% 45%);
  --color-success-700: hsl(158 76% 35%);
  --color-success-800: hsl(158 76% 25%);
  --color-success-900: hsl(158 76% 15%);
  --color-success-950: hsl(158 76% 10%);
  --color-success-975: hsl(158 76% 7%);
  --color-success-1000: hsl(158 76% 5%);

  --color-warning-100: hsl(53 100% 95%);
  --color-warning-125: hsl(53 100% 93%);
  --color-warning-150: hsl(53 100% 90%);
  --color-warning-200: hsl(53 100% 85%);
  --color-warning-300: hsl(53 100% 75%);
  --color-warning-400: hsl(53 100% 65%);
  --color-warning-500: hsl(53, 100%, 47%);
  --color-warning-600: hsl(53 100% 45%);
  --color-warning-700: hsl(53 100% 35%);
  --color-warning-800: hsl(53 100% 25%);
  --color-warning-900: hsl(53 100% 15%);
  --color-warning-950: hsl(53 100% 10%);
  --color-warning-975: hsl(53 100% 7%);
  --color-warning-1000: hsl(53 100% 5%);

  --color-error-100: hsl(4 100% 95%);
  --color-error-125: hsl(4 100% 93%);
  --color-error-150: hsl(4 100% 90%);
  --color-error-200: hsl(4 100% 85%);
  --color-error-300: hsl(4 100% 75%);
  --color-error-400: hsl(4 100% 65%);
  --color-error-500: hsl(4, 73%, 51%);
  --color-error-600: hsl(4 100% 45%);
  --color-error-700: hsl(4 100% 35%);
  --color-error-800: hsl(4 100% 25%);
  --color-error-900: hsl(4 100% 15%);
  --color-error-950: hsl(4 100% 10%);
  --color-error-975: hsl(4 100% 7%);
  --color-error-1000: hsl(4 100% 5%);

  // Universal semantic colors
  --color-component-bg-100: var(--color-grey-100);
  --color-component-bg-200: var(--color-grey-200);
  --color-component-bg-300: var(--color-grey-300);
  --color-component-border-100: var(--color-grey-200);
  --color-component-border-200: var(--color-grey-300);
  --color-component-border-300: var(--color-grey-400);
  --color-text-100: var(--clr-global-font-color);
  --color-text-200: var(--clr-global-font-color-secondary);
  --color-text-300: var(--color-grey-400);
  --clr-global-font-color: hsl(198, 0%, 40%);
  --clr-list-item-color: var(--clr-global-font-color);
  --color-text-inverse: white;
  --color-text-active: var(--color-primary-800);

  // Component-specific colors
  --color-scrollbar-bg: var(--color-weight-150);
  --color-scrollbar-thumb: var(--color-weight-300);
  --color-scrollbar-thumb-hover: var(--color-weight-400);

  --color-top-bar-bg: white;
  --color-card-border: var(--color-weight-200);
  --color-card-bg: hsl(0 0% 99%);

  --color-left-nav-bg: var(--color-weight-100);
  --color-left-nav-text: var(--color-text);
  --color-left-nav-text-hover: var(--color-primary-700);
  --color-surface-bg: white;
  --color-page-header: hsl(0 0% 98%);
  --color-page-header-item-bg: var(--color-weight-125);

  --color-button-bg: hsl(0 0% 98%);
  --color-button-hover-bg: var(--color-weight-100);
  --color-button-ghost-bg: white;
  --color-button-ghost-text: var(--color-text-100);
  --color-button-ghost-hover-text: var(--color-primary-700);
  --color-button-ghost-hover-bg: var(--color-primary-100);
  --color-button-small-bg: var(--color-weight-150);
  --color-button-small-hover-bg: var(--color-weight-200);
  --color-button-small-text: var(--color-weight-700);

  --color-channel-switcher-bg: var(--color-weight-150);
  --color-channel-switcher-hover-bg: var(--color-weight-125);

  --color-icon-button: var(--color-grey-600);
  --color-icon-button-hover: var(--color-primary-600);
  --color-form-input-bg: white;
  --color-form-input-focus: var(--color-primary-100);

  --color-chip-warning-border: var(--color-warning-500);
  --color-chip-warning-text: var(--color-warning-800);
  --color-chip-warning-bg: var(--color-warning-150);
  --color-chip-success-border: var(--color-success-200);
  --color-chip-success-text: var(--color-success-800);
  --color-chip-success-bg: var(--color-success-150);
  --color-chip-error-border: var(--color-error-200);
  --color-chip-error-text: var(--color-error-800);
  --color-chip-error-bg: var(--color-error-150);

  --color-json-editor-background-color: var(--color-grey-200);
  --color-json-editor-text: var(--color-grey-600);
  --color-json-editor-string: var(--color-secondary-600);
  --color-json-editor-number: var(--color-primary-700);
  --color-json-editor-boolean: var(--color-primary-700);
  --color-json-editor-null: var(--color-grey-500);
  --color-json-editor-key: var(--color-success-800);
  --color-json-editor-error: var(--color-error-700);

  --color-table-header-border: var(--color-weight-200);
  --color-table-row-hover-bg: var(--color-weight-100);
  --color-table-row-active-bg: var(--color-primary-100);
  --color-table-row-separator: var(--color-weight-100);
  --data-table-filter-box-shadow: inset -1px 6px 5px 0px rgb(165 165 165 / 8%);

  --color-split-view-separator-border: var(--color-weight-150);
  --color-split-view-separator-resize-border: var(--color-primary-400);

  --color-dropdown-item-focus-outline: rgba(77, 207, 255, 0.53);

  // Layout
  //   --layout-content-max-width: 1400px;
  //   --left-nav-width: 0px;
  //   --surface-width: 100vw;
  //   --surface-margin-left: 8px;

  // @media screen and (min-width: $breakpoint-small) {
  //     //--left-nav-width: 100px;
  //     //--surface-width: calc(100vw - 100px);
  //     //--surface-margin-left: 4px;
  // }

  // @media screen and (min-width: $breakpoint-medium) {
  //     --left-nav-width: 250px;
  //     --surface-width: calc(100vw - 250px);
  //     --surface-margin-left: calc(var(--space-unit) * 4);
  // }

  // @media screen and (min-width: $breakpoint-large) {
  //     --left-nav-width: 300px;
  //     --surface-width: calc(100vw - 300px);
  //     --surface-margin-left: calc(var(--space-unit) * 6);
  // }

  // // Login page
  // --login-wrapper-inner-bg: var(--color-white);
  // --login-page-bg: url(data:image/gif;base64,R0lGODlhFAAhAKIAABwcHBsbGxcXFx0dHRUVFRkZGR4eHhQUFCH/C1hNUCBEYXRhWE1QPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4gPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS4zLWMwMTEgNjYuMTQ2NzI5LCAyMDEyLzA1LzAzLTEzOjQwOjAzICAgICAgICAiPiA8cmRmOlJERiB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiPiA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtbG5zOnhtcE1NPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvbW0vIiB4bWxuczpzdFJlZj0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL3NUeXBlL1Jlc291cmNlUmVmIyIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgRWxlbWVudHMgMTIuMCBXaW5kb3dzIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjhBOTg1NDlCNjMwNDExRUFBNEUwQ0M2RDdENUQ3RTBFIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjhBOTg1NDlDNjMwNDExRUFBNEUwQ0M2RDdENUQ3RTBFIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6OEE5ODU0OTk2MzA0MTFFQUE0RTBDQzZEN0Q1RDdFMEUiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6OEE5ODU0OUE2MzA0MTFFQUE0RTBDQzZEN0Q1RDdFMEUiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz4B//79/Pv6+fj39vX08/Lx8O/u7ezr6uno5+bl5OPi4eDf3t3c29rZ2NfW1dTT0tHQz87NzMvKycjHxsXEw8LBwL++vby7urm4t7a1tLOysbCvrq2sq6qpqKempaSjoqGgn56dnJuamZiXlpWUk5KRkI+OjYyLiomIh4aFhIOCgYB/fn18e3p5eHd2dXRzcnFwb25tbGtqaWhnZmVkY2JhYF9eXVxbWllYV1ZVVFNSUVBPTk1MS0pJSEdGRURDQkFAPz49PDs6OTg3NjU0MzIxMC8uLSwrKikoJyYlJCMiISAfHh0cGxoZGBcWFRQTEhEQDw4NDAsKCQgHBgUEAwIBAAAh+QQAAAAAACwAAAAAFAAhAAADfSi23M7igEcZOIfUSvApm1N42RAuQ0cqZ0Ri0xa8nlaltAdSY44RJsfAt3q4iLDGDFlj4Ji6RQ/6GwypqyOtwO12BQKv+EawnRq93dlwLa0NWtZpSYptniRzZfpSP9o0QBVaNHJKUHYoKkh6BnxIaoBMgnBYGAp0lgCLlgQJADs=);
  // --color-login-gradient-top: var(--color-primary-800);
  // --color-login-gradient-bottom: black;

  // // Border radius
  // --clr-global-borderradius: 4px;
  // --border-radius-sm: calc(var(--clr-global-borderradius) / 2);
  // --border-radius: var(--clr-global-borderradius);
  // --border-radius-lg: calc(var(--space-unit) * 3);
  // --border-radius-img: var(--clr-global-borderradius);
  // --border-radius-input: var(--clr-global-borderradius);

  // // typography
  // --font-size-xs: 12px;
  // --font-size-sm: 14px;
  // --font-size-base: 16px;
  // --font-size-lg: 18px;
  // --font-size-xl: 20px;
  // --cds-global-typography-body-line-height: 24px;
  // --cds-alias-typography-body-line-height: 16px;
  // --cds-alias-typography-body-letter-spacing: normal;
  // --cds-alias-typography-display-letter-spacing: normal;
  // --cds-alias-typography-secondary-font-size: 13px;
  // --cds-alias-typography-font-size-3: var(--font-size-sm);
  // --cds-global-typography-font-size-4: var(--cds-alias-typography-secondary-font-size);

  // spacing
  //   --space-unit: 8px;

  //   // clarity styles
  //   --clr-link-visited-color: var(--color-weight-700);
  //   --clr-link-hover-color: var(--color-weight-700);
  //   --clr-link-active-color: var(--color-weight-700);
  //   --clr-link-color: var(--color-weight-700);
  //   --clr-list-item-color: var(--color-weight-700);
}
